@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?9tmtez');
    src: url('../fonts/icomoon.eot?9tmtez#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?9tmtez') format('truetype'), url('../fonts/icomoon.woff?9tmtez') format('woff'),
        url('../fonts/icomoon.svg?9tmtez#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-ev-charger-converted-1:before {
    content: "\e95c";
  }
.icon-Inductive-Loops-converted:before {
    content: '\e956';
}
.icon-Ticketing-converted:before {
    content: '\e957';
}
.icon-Barrier-free-Access-converted:before {
    content: '\e959';
}
.icon-Braille--Tactile-Plans-converted:before {
    content: '\e95a';
}
.icon-Venue-Accessibility-converted:before {
    content: '\e95b';
}
.icon-ev_charge-converted:before {
    content: '\e955';
}
.icon-k_dollar:before {
    content: '\e958';
    color: #877750;
}
.icon-tickCarPark:before {
    content: '\e954';
}
.icon-balleting:before {
    content: '\e953';
    color: #283266;
}
.icon-teamup1:before {
    content: '\e952';
}
.icon-record:before {
    content: '\e951';
}
.icon-filter:before {
    content: '\e950';
    color: #231448;
}
.icon-book01:before {
    content: '\e900';
}
.icon-ticket02:before {
    content: '\e901';
}
.icon-location01:before {
    content: '\e902';
}
.icon-ticket01:before {
    content: '\e903';
}
.icon-Accessibility01:before {
    content: '\e904';
}
.icon-treasure:before {
    content: '\e905';
}
.icon-headphone:before {
    content: '\e906';
}
.icon-wheelchair:before {
    content: '\e907';
}
.icon-App:before {
    content: '\e908';
}
.icon-accessibilty02:before {
    content: '\e909';
}
.icon-Service:before {
    content: '\e90a';
}
.icon-user_star:before {
    content: '\e90b';
}
.icon-ins:before {
    content: '\e90c';
}
.icon-youtube:before {
    content: '\e90d';
}
.icon-twitter:before {
    content: '\e90e';
}
.icon-facebook:before {
    content: '\e90f';
}
.icon-navigation:before {
    content: '\e910';
}
.icon-arrow01:before {
    content: '\e911';
}
.icon-website:before {
    content: '\e912';
}
.icon-arrow02:before {
    content: '\e913';
}
.icon-arrow03:before {
    content: '\e914';
}
.icon-Accessibility:before {
    content: '\e915';
}
.icon-logout:before {
    content: '\e916';
}
.icon-download:before {
    content: '\e917';
}
.icon-balloting:before {
    content: '\e918';
}
.icon-ticket:before {
    content: '\e919';
}
.icon-taxi:before {
    content: '\e91a';
}
.icon-bus:before {
    content: '\e91b';
}
.icon-mtr:before {
    content: '\e91c';
}
.icon-ferry:before {
    content: '\e91d';
}
.icon-wifi:before {
    content: '\e91e';
}
.icon-view:before {
    content: '\e91f';
}
.icon-view_off:before {
    content: '\e920';
}
.icon-minus:before {
    content: '\e921';
}
.icon-plus:before {
    content: '\e922';
}
.icon-arrow_up:before {
    content: '\e923';
}
.icon-arrow_down:before {
    content: '\e924';
}
.icon-arrow_right:before {
    content: '\e925';
}
.icon-arrow_left:before {
    content: '\e926';
}
.icon-organisation:before {
    content: '\e927';
}
.icon-settings:before {
    content: '\e928';
}
.icon-announce:before {
    content: '\e929';
}
.icon-price:before {
    content: '\e92a';
}
.icon-enquire:before {
    content: '\e92b';
}
.icon-time:before {
    content: '\e92c';
}
.icon-location:before {
    content: '\e92d';
}
.icon-profile:before {
    content: '\e92e';
}
.icon-search:before {
    content: '\e92f';
}
.icon-home:before {
    content: '\e930';
}
.icon-fitness:before {
    content: '\e931';
}
.icon-coach:before {
    content: '\e932';
}
.icon-list:before {
    content: '\e933';
}
.icon-grid:before {
    content: '\e934';
}
.icon-mail:before {
    content: '\e935';
}
.icon-date:before {
    content: '\e936';
}
.icon-me_hit:before {
    content: '\e937';
}
.icon-explore_hit:before {
    content: '\e938';
}
.icon-arrow04:before {
    content: '\e939';
}
.icon-chatbot:before {
    content: '\e93a';
}
.icon-close:before {
    content: '\e93b';
}
.icon-experience:before {
    content: '\e93c';
}
.icon-sport:before {
    content: '\e93d';
}
.icon-canlender:before {
    content: '\e93e';
}
.icon-shop_bag:before {
    content: '\e93f';
}
.icon-dinning:before {
    content: '\e940';
}
.icon-park:before {
    content: '\e941';
}
.icon-book:before {
    content: '\e942';
}
.icon-shop_cart:before {
    content: '\e943';
}
.icon-hotel:before {
    content: '\e944';
}
.icon-outdoor:before {
    content: '\e945';
}
.icon-promotion:before {
    content: '\e946';
}
.icon-share:before {
    content: '\e947';
}
.icon-sport-training:before {
    content: '\e948';
}
.icon-bookmark:before {
    content: '\e949';
}
.icon-teamup:before {
    content: '\e94a';
}
.icon-back_top:before {
    content: '\e94b';
}
.icon-announcement:before {
    content: '\e94c';
}
/* .icon-bookmark_hit .path1:before {
    content: '\e94d';
    color: rgb(218, 226, 244);
}
.icon-bookmark_hit .path2:before {
    content: '\e94e';
    margin-left: -1em;
    color: ;
}
.icon-bookmark_hit .path3:before {
    content: '\e94f';
    margin-left: -1em;
    color: ;
} */
