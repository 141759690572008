@font-face {
    font-family: 'Barlow';
    src: url('resources/fonts/Barlow-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('resources/fonts/Barlow-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('resources/fonts/Barlow-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('resources/fonts/Barlow-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MicrosoftJhengHei';
    src: url('resources/fonts/Microsoft JhengHei-regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MicrosoftJhengHei';
    src: url('resources/fonts/Microsoft JhengHei-regular.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'MicrosoftJhengHei';
    src: url('resources/fonts/Microsoft JhengHei-bold.ttc');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'MicrosoftJhengHei';
    src: url('resources/fonts/Microsoft JhengHei-bold.ttc');
    font-weight: 700;
    font-style: normal;
}
