.thinScrollBar::-webkit-scrollbar {
    width: 8px; /* 宽度 */
    height: 8px; /* 高度 */
}

/* 滚动条样式 */
.thinScrollBar::-webkit-scrollbar-thumb {
    background-color: #c1c1c1; /* 滚动条颜色 */
    border-radius: 4px; /* 圆角 */
}

/* 滚动条轨道样式 */
.thinScrollBar::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* 轨道颜色 */
}
/* 滚动条整体样式 */
.thinScrollBar {
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #c1c1c1 #f1f1f1; /* Firefox */
}

/* 滚动条轨道样式 */
.thinScrollBar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* 滚动条滑块样式 */
.thinScrollBar::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;
}

/* Firefox 自定义滚动条样式 */
@-moz-document url-prefix() {
    .thinScrollBar {
        scrollbar-width: 8px;
        scrollbar-color: #c1c1c1 #f1f1f1;
    }
}
