@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('resources/iconResources/style.css');
@import url('resources/fonts/fonts.css');

@layer base {
    :root {
        --KT-Red: '#EC0032',
        --KT-Grey-Dim: '#D4D6D9'
    }
}

@layer components {
    .bg-website-gradation {
        background: linear-gradient(45deg, #8f0044, #bc003c, #e20030, #ff1636);
    }
    .bg-website-gradation-hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(73deg, #8F0044 11.91%, #BC003C 37.3%, #E20030 63.49%, #FF1636 88.09%);
    }
    .bg-grey-dim {
        background: #D4D6D9;
    }
    .bg-white-gradation {
        background: linear-gradient(180deg, #5A5A5A 40%, rgba(90, 90, 90, 0.00) 100%);
    }
    .landing-pt {
        @apply pt-8 md:pt-[64px];
    }
    .bg-container {
        @apply w-full px-5 md:px-10 lg:px-[60px] xl:px-[150px] 2xl:max-w-[1440px] 2xl:mx-auto;
    }
    .font-semiboldFamily {
        @apply !font-semibold;
    }
    .font-boldFamily {
        @apply !font-bold;
    }
    .font-mediumFamily {
        @apply !font-medium;
    }
    .font-regular {
        @apply !font-normal;
    }
    .text-title {
        @apply font-bold md:font-semibold text-[22px] md:text-[36px] leading-[22px] md:leading-[42px];
    }
    .scale-image{
        @apply transition-transform duration-500 ease-in-out hover:scale-110
    }
    .scrollbar-hide::-webkit-scrollbar {  
        display: none;  
      }
    
    .text-h1 {
        @apply font-semiboldFamily text-[33px] md:text-[48px] leading-[120%]
    }
    .text-h2 {
        @apply font-semiboldFamily text-[28px] md:text-[42px] leading-[120%]
    }
    .text-h3 {
        @apply font-semiboldFamily text-[22px] md:text-[36px] leading-[120%]
    }
    .text-h4 {
        @apply font-semiboldFamily text-[21px] md:text-[24px] leading-[120%]
    }
    .text-h5 {
        @apply font-semiboldFamily text-[16px] md:text-[20px] leading-[120%]
    }
    .text-h6 {
        @apply font-semiboldFamily text-[18px] md:text-[22px] leading-[120%]
    }
    .text-body1 {
        @apply font-mediumFamily text-[14px] md:text-[16px] leading-[150%]
    }
    .text-body2 {
        @apply font-semiboldFamily text-[14px] md:text-[16px] leading-[150%]
    }
    .text-body3 {
        @apply font-mediumFamily text-[12px] md:text-[14px] leading-[120%]
    }
    .text-body4 {
        @apply font-mediumFamily text-[12px] md:text-[14px] leading-[140%]
    }
    .text-button1 {
        @apply font-semiboldFamily text-[18px] md:text-[24px]
    }
    .text-button2 {
        @apply font-semiboldFamily text-[14px] md:text-[18px]
    }
    .text-button3 {
        @apply font-semiboldFamily text-[12px] md:text-[16px]
    }
    .text-button4 {
        @apply font-semiboldFamily text-[18px] md:text-[24px]
    }
    .text-button5 {
        @apply font-semiboldFamily text-[12px] md:text-[16px] underline
    }
    .text-category {
        @apply font-mediumFamily text-[12px]
    }
    .text-label {
        @apply font-boldFamily text-[14px] italic
    }
    .text-tag {
        @apply font-mediumFamily text-[12px] md:text-[14px] 
    }
    .text-caption {
        @apply font-mediumFamily text-[12px]
    }
    .text-tab {
        @apply font-mediumFamily text-[16px]
    }
    .text-tab-selected {
        @apply font-semiboldFamily text-[16px]
    }
    .icon-16 {
        @apply text-base leading-4
    }
    .icon-24 {
        @apply text-2xl leading-6
    }

    .textarea-content {
        @apply break-words whitespace-pre !text-wrap;
        word-break: break-word;
    }

    @media (max-width: 767px) {
        .partner-box {
            column-gap: 18% !important;
        }
    }
}

.font-kt {
    font-family: 'Barlow', 'MicrosoftJhengHei', sans-serif;
}

.font-kt-en {
    font-family: 'Barlow', sans-serif;
}

.font-kt-zh {
    font-family: 'MicrosoftJhengHei', sans-serif;
}

.detail-page-text {
    strong {
        @apply text-[20px] font-semiboldFamily;
    }
}

.html-content {
    h2 {
        font-size: 24px;
        font-weight: 700;
    }
    ol {
        list-style: decimal;
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }
    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }
    a {
        color: #EC0032;
        text-decoration: underline;
    }
}

.description-html {
figure,table {
    width: 100%;
    margin: 0 !important;
    border: 0 !important;
}
.table table tbody tr td {
    border: 0;
    padding: 0;
}
}