.iconList {
    display: flex;
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    gap: 16px;
}

@media (max-width: 550px) {
    .iconList {
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto auto;
        justify-content: center;
    }

    .iconList .iconItem {
        grid-column: span 2;
    }
    .iconList .iconItem:nth-child(4),
    .iconList .iconItem:nth-child(5) {
        grid-column: span 3;
        justify-self: center;
    }
}